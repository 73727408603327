<template>
  <div>
  <!-- Table Container Card -->
   <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col md="12">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-1 variant="outline-info">
                    <h4>PM Schedule</h4>
                    <b-badge variant="outline-success">
                      Untuk mengirim ulang chatbot PM Schedule (08:00 & 17:00)
                    </b-badge>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                     <b-row>
                      <b-col>
                        <label>From</label>
                        <flat-pickr
                          v-model="tgl_from"
                          :value="statusFilter"
                          class="form-control"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :reduce="val => val.value"
                          @input="(val) => $emit('update:statusFilter', val)"
                        />
                      </b-col>
                      <b-col>
                        <b-button
                          class="mt-2" 
                          variant="primary"
                          type="submit"
                          :disabled="btnDisable"
                          @click.prevent="sendPM"
                        >
                          <div v-if="btnDisable">
                            <b-spinner small></b-spinner>
                              Loading...
                          </div>
                          <div v-else>
                                Send
                          </div>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-2 variant="outline-info">
                    <h4>Energy</h4>
                    <b-badge variant="outline-success">
                      Untuk mengirim ulang chatbot Energy (10:00)
                    </b-badge>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                     <b-row>
                      <b-col>
                        <label>From</label>
                        <flat-pickr
                          v-model="tgl_from"
                          :value="statusFilter"
                          class="form-control"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :reduce="val => val.value"
                          @input="(val) => $emit('update:statusFilter', val)"
                        />
                      </b-col>
                      <b-col>
                        <b-button
                          class="mt-2" 
                          variant="primary"
                          type="submit"
                          :disabled="btnDisable"
                          @click.prevent="sendEnergy"
                        >
                          <div v-if="btnDisable">
                            <b-spinner small></b-spinner>
                              Loading...
                          </div>
                          <div v-else>
                                Send
                          </div>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-3 variant="outline-info">
                    <h4>Tag Assignment</h4>
                    <b-badge variant="outline-success">
                      Untuk mengirim ulang chatbot Tag Assigment (08:00 & 17:00)
                    </b-badge>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row>
                      <b-col>
                        <b-button
                          class="mt-2" 
                          variant="primary"
                          type="submit"
                          :disabled="btnDisable"
                          @click.prevent="sendTagAssignment"
                        >
                          <div v-if="btnDisable">
                            <b-spinner small></b-spinner>
                              Loading...
                          </div>
                          <div v-else>
                                Send
                          </div>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-4 variant="outline-info">
                    <h4>Powemeter</h4>
                    <b-badge variant="outline-success">
                      Untuk mengirim ulang chatbot Powermeter berdasarkan shift
                    </b-badge>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                     <strong>Shift 1 : 08:00 - 16:00.</strong> </br>
                     <strong>Shift 2 : 16:00 - 00:00.</strong> </br>
                     <strong>Shift 3 : 16:00 - 08:00.</strong> </br> </br>
                     <b-row>
                      <b-col>
                        <label>From</label>
                        <flat-pickr
                          v-model="tgl_from"
                          :value="statusFilter"
                          class="form-control"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :reduce="val => val.value"
                          @input="(val) => $emit('update:statusFilter', val)"
                        />
                      </b-col>
                      <b-col>
                        <label>Shift</label>
                        <b-form-input
                          v-model="shift"
                          class="form-control"
                          placeholder="Shift"
                          type="number"
                        />
                      </b-col>
                      <b-col>
                        <b-button
                          class="mt-2" 
                          variant="primary"
                          type="submit"
                          :disabled="btnDisable"
                          @click.prevent="sendPowermeter"
                        >
                          <div v-if="btnDisable">
                            <b-spinner small></b-spinner>
                              Loading...
                          </div>
                          <div v-else>
                                Send
                          </div>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-5 variant="outline-info">
                    <h4>Mixing</h4>
                    <b-badge variant="outline-success">
                      Untuk mengirim ulang chatbot Mixing berdasarkan shift
                    </b-badge>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                     <strong>Shift 1 : 08:00 - 16:00.</strong> </br>
                     <strong>Shift 2 : 16:00 - 00:00.</strong> </br>
                     <strong>Shift 3 : 16:00 - 08:00.</strong> </br> </br>
                     <b-row>
                      <b-col>
                        <label>From</label>
                        <flat-pickr
                          v-model="tgl_from"
                          :value="statusFilter"
                          class="form-control"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :reduce="val => val.value"
                          @input="(val) => $emit('update:statusFilter', val)"
                        />
                      </b-col>
                      <b-col>
                        <label>Shift</label>
                        <b-form-input
                          v-model="shift"
                          class="form-control"
                          placeholder="Shift"
                          type="number"
                        />
                      </b-col>
                      <b-col>
                        <b-button
                          class="mt-2" 
                          variant="primary"
                          type="submit"
                          :disabled="btnDisable"
                          @click.prevent="sendMixing"
                        >
                          <div v-if="btnDisable">
                            <b-spinner small></b-spinner>
                              Loading...
                          </div>
                          <div v-else>
                                Send
                          </div>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-6 variant="outline-info">
                    <h4>Mixing Azo</h4>
                    <b-badge variant="outline-success">
                      Untuk mengirim ulang chatbot Mixing Azo berdasarkan shift
                    </b-badge>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                     <strong>Shift 1 : 08:00 - 16:00.</strong> </br>
                     <strong>Shift 2 : 16:00 - 00:00.</strong> </br>
                     <strong>Shift 3 : 16:00 - 08:00.</strong> </br> </br>
                     <b-row>
                      <b-col>
                        <label>From</label>
                        <flat-pickr
                          v-model="tgl_from"
                          :value="statusFilter"
                          class="form-control"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :reduce="val => val.value"
                          @input="(val) => $emit('update:statusFilter', val)"
                        />
                      </b-col>
                      <b-col>
                        <label>Shift</label>
                        <b-form-input
                          v-model="shift"
                          class="form-control"
                          placeholder="Shift"
                          type="number"
                        />
                      </b-col>
                      <b-col>
                        <b-button
                          class="mt-2" 
                          variant="primary"
                          type="submit"
                          :disabled="btnDisable"
                          @click.prevent="sendAzo"
                        >
                          <div v-if="btnDisable">
                            <b-spinner small></b-spinner>
                              Loading...
                          </div>
                          <div v-else>
                                Send
                          </div>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-7 variant="outline-info">
                    <h4>Pelletizer</h4>
                    <b-badge variant="outline-success">
                      Untuk mengirim ulang chatbot Pelletizer berdasarkan shift
                    </b-badge>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                     <strong>Shift 1 : 08:00 - 16:00.</strong> </br>
                     <strong>Shift 2 : 16:00 - 00:00.</strong> </br>
                     <strong>Shift 3 : 16:00 - 08:00.</strong> </br> </br>
                     <b-row>
                      <b-col>
                        <label>From</label>
                        <flat-pickr
                          v-model="tgl_from"
                          :value="statusFilter"
                          class="form-control"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :reduce="val => val.value"
                          @input="(val) => $emit('update:statusFilter', val)"
                        />
                      </b-col>
                      <b-col>
                        <label>Shift</label>
                        <b-form-input
                          v-model="shift"
                          class="form-control"
                          placeholder="Shift"
                          type="number"
                        />
                      </b-col>
                      <b-col>
                        <b-button
                          class="mt-2" 
                          variant="primary"
                          type="submit"
                          :disabled="btnDisable"
                          @click.prevent="sendPelletizer"
                        >
                          <div v-if="btnDisable">
                            <b-spinner small></b-spinner>
                              Loading...
                          </div>
                          <div v-else>
                                Send
                          </div>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-8 variant="outline-info">
                    <h4>Tag Logger</h4>
                    <b-badge variant="outline-success">
                      Untuk mengirim ulang chatbot Tag Logger berdasarkan shift
                    </b-badge>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                     <strong>Shift 1 : 08:00 - 16:00.</strong> </br>
                     <strong>Shift 2 : 16:00 - 00:00.</strong> </br>
                     <strong>Shift 3 : 16:00 - 08:00.</strong> </br> </br>
                     <b-row>
                      <b-col>
                        <label>From</label>
                        <flat-pickr
                          v-model="tgl_from"
                          :value="statusFilter"
                          class="form-control"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :reduce="val => val.value"
                          @input="(val) => $emit('update:statusFilter', val)"
                        />
                      </b-col>
                      <b-col>
                        <label>Shift</label>
                        <b-form-input
                          v-model="shift"
                          class="form-control"
                          placeholder="Shift"
                          type="number"
                        />
                      </b-col>
                      <b-col>
                        <b-button
                          class="mt-2" 
                          variant="primary"
                          type="submit"
                          :disabled="btnDisable"
                          @click.prevent="sendTagLogger"
                        >
                          <div v-if="btnDisable">
                            <b-spinner small></b-spinner>
                              Loading...
                          </div>
                          <div v-else>
                                Send
                          </div>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>

               <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-9 variant="outline-info">
                    <h4>Predictive</h4>
                    <b-badge variant="outline-success">
                      Untuk mengirim ulang chatbot Predictive
                    </b-badge>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-row>
                      <b-col>
                        <b-button
                          class="mt-2" 
                          variant="primary"
                          type="submit"
                          :disabled="btnDisable"
                          @click.prevent="sendPredictive"
                        >
                          <div v-if="btnDisable">
                            <b-spinner small></b-spinner>
                              Loading...
                          </div>
                          <div v-else>
                                Send
                          </div>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>

            </div>
          </b-col>  
        </b-row>
      </div>
    </b-card>
    
  <!-- Table Container Card -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BButtonGroup, BTab, BTabs, BFormGroup,
  BFormCheckbox,BContainer,BForm,BCardText,BSpinner,BCollapse,CollapsePlugin,
  VBToggle,BadgePlugin,BBadge,CardPlugin,BCardHeader,BCardBody   // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-cycle
import useList from './useList'
import StoreModule from '../StoreModule'

export default {
  components: {
    BCard,
    BRow,
    BForm,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BContainer,
    BCardText,
    BSpinner,
    // BDropdown,
    // BDropdownItem,
    BButtonGroup,
    BTab,
    BTabs,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    required,
    flatPickr,
    vSelect,
    BFormGroup,
    BCollapse,
    CollapsePlugin,
    VBToggle,
    BadgePlugin,
    'b-badge': BBadge,
    CardPlugin,
    BCardHeader,
    BCardBody,
  },
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
  },
  directives: {
    Ripple,
    'b-toggle':  VBToggle,
  },
  data: () => ({
     tgl_from: `${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}`,
     shift:1,
  }),
  created(){

  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'     
    const btnDisable= ref(false);
    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
    } = useList()

    return {
     

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,

      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      btnDisable,
    }
  },
  methods: {
   async sendPM() {
      const params = new URLSearchParams()
      params.append('tanggal',this.tgl_from)
      //params.append('data', JSON.stringify(this.PowerMeterData))
      //params.append('id', window.localStorage.getItem("id"))
      this.btnDisable = true;
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot/pm_schedule`,{ 
        params:params})
      if (result.data.message === 'Data Updated') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data Send',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Danger',
            icon: 'BellIcon',
            text: 'Error Saving Data!',
            variant: 'danger',
          },
        })
      }  
      this.btnDisable = false;
    },
    async sendEnergy() {
      const params = new URLSearchParams()
      params.append('tanggal',this.tgl_from.replace(/\//g, '-'))
      //params.append('data', JSON.stringify(this.PowerMeterData))
      //params.append('id', window.localStorage.getItem("id"))
      this.btnDisable = true;
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot/energy`,{ 
        params:params})
      if (result.data.message === 'Data Updated') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data Send',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Danger',
            icon: 'BellIcon',
            text: 'Error Saving Data!',
            variant: 'danger',
          },
        })
      }
      this.btnDisable = false;  
    },
    async sendTagAssignment() {
      const params = new URLSearchParams()
      params.append('site',3)
      //params.append('data', JSON.stringify(this.PowerMeterData))
      //params.append('id', window.localStorage.getItem("id"))
      this.btnDisable = true;
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot/tag_assignment`,{ 
        params:params})
      if (result.data.message === 'Data Updated') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data Send',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Danger',
            icon: 'BellIcon',
            text: 'Error Saving Data!',
            variant: 'danger',
          },
        })
      }
      this.btnDisable = false;  
    },
    async sendPowermeter() {
      const params = new URLSearchParams()
      params.append('tanggal',this.tgl_from.replace(/\//g, '-'))
       params.append('shift',this.shift)
      //params.append('data', JSON.stringify(this.PowerMeterData))
      //params.append('id', window.localStorage.getItem("id"))
      this.btnDisable = true;
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot/powermeter_routine`,{ 
        params:params})
      if (result.data.message === 'Data Updated') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data Send',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Danger',
            icon: 'BellIcon',
            text: 'Error Saving Data!',
            variant: 'danger',
          },
        })
      }
      this.btnDisable = false;  
    },
     async sendMixing() {
      const params = new URLSearchParams()
      params.append('tanggal',this.tgl_from.replace(/\//g, '-'))
       params.append('shift',this.shift)
      //params.append('data', JSON.stringify(this.PowerMeterData))
      //params.append('id', window.localStorage.getItem("id"))
      this.btnDisable = true;
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot/mixing_routine`,{ 
        params:params})
      if (result.data.message === 'Data Updated') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data Send',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Danger',
            icon: 'BellIcon',
            text: 'Error Saving Data!',
            variant: 'danger',
          },
        })
      }
      this.btnDisable = false;  
    },
       async sendAzo() {
      const params = new URLSearchParams()
      params.append('tanggal',this.tgl_from.replace(/\//g, '-'))
       params.append('shift',this.shift)
      //params.append('data', JSON.stringify(this.PowerMeterData))
      //params.append('id', window.localStorage.getItem("id"))
      this.btnDisable = true;
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot/azo_routine`,{ 
        params:params})
      if (result.data.message === 'Data Updated') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data Send',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Danger',
            icon: 'BellIcon',
            text: 'Error Saving Data!',
            variant: 'danger',
          },
        })
      }
      this.btnDisable = false;  
    },
    async sendPelletizer() {
      const params = new URLSearchParams()
      params.append('tanggal',this.tgl_from.replace(/\//g, '-'))
       params.append('shift',this.shift)
      //params.append('data', JSON.stringify(this.PowerMeterData))
      //params.append('id', window.localStorage.getItem("id"))
      this.btnDisable = true;
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot/pelletizer_routine`,{ 
        params:params})
      if (result.data.message === 'Data Updated') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data Send',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Danger',
            icon: 'BellIcon',
            text: 'Error Saving Data!',
            variant: 'danger',
          },
        })
      }
      this.btnDisable = false;  
    },
    async sendTagLogger() {
      const params = new URLSearchParams()
      params.append('tanggal',this.tgl_from.replace(/\//g, '-'))
       params.append('shift',this.shift)
      //params.append('data', JSON.stringify(this.PowerMeterData))
      //params.append('id', window.localStorage.getItem("id"))
      this.btnDisable = true;
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot/tag_logger_routine`,{ 
      params:params})
      if (result.data.message === 'Data Updated') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data Send',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Danger',
            icon: 'BellIcon',
            text: 'Error Saving Data!',
            variant: 'danger',
          },
        })
      }
      this.btnDisable = false;  
    },
    async sendPredictive() {
      const params = new URLSearchParams()
      this.btnDisable = true;
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chatbot/preventive_header`)
      if (result.data.message === 'Data Updated') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data Send',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Danger',
            icon: 'BellIcon',
            text: 'Error Saving Data!',
            variant: 'danger',
          },
        })
      }
      this.btnDisable = false;  
    },
    async synTableMES() {
      /*   let data=[];
        if(this.tabIndex == 0){
          data = {table : 1};
          this.pmDisable = true;
        }else if (this.tabIndex == 3){
          data = {table : 2};
          this.plDisable = true;
        }else if (this.tabIndex == 4){
          data = {table : 3};
          this.tlDisable = true;
        }
        const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/chatbot-setting/sync-table`, data)
        if (result.data.message === 'Data Updated') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Data Updated',
              variant: 'success',
            },
          })
          if(this.tabIndex == 0){
            this.pmDisable = false;
            await this.getPowerMeter();
            this.PowerMeterData =  this.PowerMeterTemp;
          }else if (this.tabIndex == 3){
            this.plDisable = false;
            await this.getPelletizer();
            this.PelletizerData =  this.PelletizerTemp;
          }else if (this.tabIndex == 4){
            this.tlDisable = false;
            await this.getTagLogger();
            this.TagLoggerData =  this.TagLoggerTemp;
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Danger',
              icon: 'BellIcon',
              text: 'Error Saving Data!',
              variant: 'danger',
            },
          })
           if(this.tabIndex == 0){
            this.pmDisable = false;
          }else if (this.tabIndex == 3){
            this.plDisable = false;
          }else if (this.tabIndex == 4){
            this.tlDisable = false;
          }
        } */ 
    },
     async filterPowermeter() {
     /*  let value = this.PowerMeterFilter.toUpperCase();
      if (this.PowerMeterFilter !== ''){
          await this.getPowerMeter();
          this.PowerMeterData =  this.PowerMeterTemp;
          this.PowerMeterData= this.PowerMeterData.filter((word) =>{
           let paramaterFilter = word.parameter;
           paramaterFilter = paramaterFilter.toUpperCase(); 
           //console.log(paramaterFilter.toUpperCase());
           return paramaterFilter.match(value);
          });
      }else{
        this.getPowerMeter();
        this.PowerMeterData =  this.PowerMeterTemp;
      } */
    },
    
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
